<template>
  <a-skeleton
    avatar
    :title="{ width: '40%' }"
    :paragraph="{
      rows: 2,
      width: ['100%', '100%'],
    }"
    class="feed-card-skeleton"
  />
</template>
<style lang="scss">
.feed-card-skeleton {
  margin: 2.4rem 0;
  .ant-skeleton-header {
    padding-right: 1rem;
    .ant-skeleton-avatar {
        width: 5rem;
        height: 5rem;
        border-radius: 100%;
    }
  }
  .ant-skeleton-content {
    .ant-skeleton-title {
        margin: 1.5rem 0 1.5rem 0;
        height: 2rem;
    }
    .ant-skeleton-paragraph {
        margin-left: -6rem;
        li {
            height: 35rem;
        }
        li + li {
            height: 4rem;
        }
    }
  }
}
</style>
